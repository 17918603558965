import React from 'react';
import { Grid } from '@mui/material';
import styles from './founding-membership.module.scss';
import { BetaTesterImg } from '../../../../assets/images';
import WhyJoin from './why-join.component';

const whyJoinData = [
  {
    icon: '../../../../assets/images/adopter.png',
    title: 'Early Adopter',
    desc: 'Join a community of creatives and thought leaders.',
    gradient: 'linear-gradient(to right, #FFD9A8, #FF8379)',
  },
  {
    icon: '../../../../assets/images/tester.png',
    title: 'Beta Tester',
    desc: 'Get early access before QuickCam is publicly launched.',
    gradient: 'linear-gradient(to right, #F3FFA8, #4AC857)',
  },
  {
    icon: '../../../../assets/images/gameCoins.png',
    title: 'Game Coins',
    desc: 'Earn Game Coins just for signing up.',
    gradient: 'linear-gradient(to right, #A8FFEF, #798EFF)',
  },
  {
    icon: null,
    title: 'Referral Link',
    desc: 'Your own custom QuickCam referral link.',
    gradient: 'linear-gradient(to right, #BFA8FF, #FF79E2)',
  },
  {
    icon: null,
    title: 'More Game Coins',
    desc: 'Earn Game Coins when your Referrals subscribe.',
    gradient: 'linear-gradient(to right, #FFA8C7, #FF7979)',
  },

  {
    icon: null,
    title: 'NFT’s',
    desc: 'Badges “converted” into NFT’s. (crowdfunding goal 4).',
    gradient: 'linear-gradient(to right, #5B94D6, #2FD8FD)',
  },
  {
    icon: null,
    title: 'Shape the Future',
    desc: 'QuickCam AI trained to add fast and slow motion (goal 7).',
    gradient: 'linear-gradient(to right, #0055D5, #CB70EB)',
  },
  {
    icon: null,
    title: 'QuickCam AI Coin',
    desc: 'Convert Game Coins into cryptocurrency (crowdfunding goal 4).',
    gradient: 'linear-gradient(to right, #FFCC48, #D479FF)',
  },
];

const FoundingMembership: React.FC = () => {
  return (
    <section className={styles.container} id="founding-membership">
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={7}>
          <h2>Founding Membership for Beta Testers!</h2>
          <div className={styles.benefitsList}>
            <ul>
              <li>
                Be an Early Adopter Beta Tester and gain first access to
                QuickCam
              </li>
              <li>First 10,000 Members Founding Members</li>
              <li>Next 40,000 Members Beta Testers</li>
              <li>Next 100,000 Members Early Adopters</li>
              <li>600,000,000 Total Quickie Meme Coins Reserved</li>
              <li>Earn Badges that convert into NFTs</li>
              <li>No Purchase Required!</li>
            </ul>
          </div>
        </Grid>
        <Grid item xs={12} md={5}>
          <div className={styles.badgeContainer}>
            <img
              src={BetaTesterImg}
              alt="Founding Member Beta Tester Badge"
              className={styles.badgeImage}
            />
          </div>
        </Grid>
      </Grid>
      <WhyJoin data={whyJoinData} />
    </section>
  );
};

export default FoundingMembership;
