import React from 'react';
import { Grid } from '@mui/material';
import styles from './game-coins-details.module.scss';

const GameCoinsDetails: React.FC = () => {
  return (
    <section className={styles.container} id="features">
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <h2>Earn Game Coins & Community Meme Coins</h2>
          <div className={styles.mainContent}>
            <p className={styles.leadText}>
              🎮 Earn QuickCam Game Coins to Get Real Crypto Airdrops!
            </p>

            <p className={styles.tagline}>🚀Refer. Earn. Convert.💰</p>

            <ul>
              <li>
                Refer QuickCam to earn QuickCam Game Coins with Pending Status!
              </li>
              <li>
                When your referrals subscribe, your Game Coins status becomes
                Confirmed.
              </li>
              <li>
                The more Confirmed QuickCam Game Coins you have, the more meme
                coins will be airdropped to your account.
              </li>
            </ul>

            <p className={styles.convertHeading}>
              🔄 Quickie & Speedy Community Meme Coins!
            </p>

            <ul>
              <li>
                🚀 Trade or Hold - Keep them as digital collectible assets or
                sell them when you want.
              </li>
              <li>
                🔥 Use them in the QuickCam Ecosystem - Unlock perks, exclusive
                features, and more!
              </li>
              <li>
                🎁 Crypto Tipping Economy – Support creators and friends with a
                tap!
              </li>
            </ul>

            <p className={styles.callToAction}>
              👉 QuickCam is your Onboarding to Web3 Economy. 🚀
            </p>
          </div>

          <div className={styles.videoPlaceholder}>
            <p>Coin transition video</p>
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default GameCoinsDetails;
