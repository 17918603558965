import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styles from './subscription-plan.module.scss';
import {
  FreeSubscription,
  BasicSubscription,
  AdvancedSubscription,
  PremiumSubscription,
  SubscriptionPlanMobile,
  SubscriptionPlanWeb,
} from '../../../../assets/images';
import FreeTrial from '../../../../assets/images/FreeTrialBg.png';
import SubscriptionBg from '../../../../assets/images/SubscriptionBg.png';
import FreeIcon from '../../../../assets/images/FreeSubIcon.png';
import PremiumIcon from '../../../../assets/images/PremiumSubIcon.png';
import { UserService } from '../../../../common/services/user/user.service';
import useWindowDimensions from '../../../../common/hooks/useWindowDimensions';
import { setRedirectURI } from '../../../../store/common/common.reducer';
import ENVIRONMENTS from '../../../../environments';

const userServices = new UserService();

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const FreeTrialBanner = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { search } = location;
  const params = useMemo(() => new URLSearchParams(search), []);

  const redirectToSignUp = () => {
    const redirectUri =
      window.location.origin +
      (location.pathname === '/' ? '' : location.pathname);
    if (location.pathname !== '/')
      window.localStorage.setItem('redirect_uri', redirectUri);
    dispatch(
      setRedirectURI({
        url: window.location.href,
        refCode: params.get('refCode') || '',
      }),
    );
    const signUphURL = `${ENVIRONMENTS.KEYCLOAK_ACCOUNT}/protocol/openid-connect/registrations?client_id=${ENVIRONMENTS.KEYCLOAK_CLIENT_ID}&response_mode=fragment&response_type=code&login=true&redirect_uri=${redirectUri}&from=Signup`;
    window.location.href = signUphURL;
  };

  return (
    <>
      <section className={styles.bannerContainer}>
        <div className={styles.webSubContainer}>
          <img src={FreeTrial} alt="subscriptionPlan" />
          <div className={styles.content}>
            <div className={styles.contentInner}>
              <h2>Premium Free Trial Now in Beta</h2>
              <h6>Click on Get Started to start today!</h6>
              <button type="button" onClick={redirectToSignUp}>
                Get Started
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FreeTrialBanner;
