import React from 'react';
import styles from './free-beta.module.scss';
import freeIcon from '../../../../assets/images/free_icon.png';

const FreeBeta = () => {
  return (
    <section className={styles.container}>
      {/* Top "Free" banner */}
      <div className={styles.freeTopBanner}>
        {Array.from({ length: 10 }, (_, index) => (
          <div
            key={index}
            className={styles.freeItem}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={freeIcon}
              alt="Free Icon"
              className={styles.freeIcon}
              width={100}
            />
          </div>
        ))}
      </div>

      {/* Main content area */}
      <div className={styles.contentArea}>
        <div className={styles.innerContainer}>
          <div className={styles.leftContent}>
            <h2 className={styles.mainHeading}>
              100% Free <br />
              in Beta
            </h2>
            <p className={styles.subHeading}>
              Subscription starts on
              <br />
              Official Launch.
            </p>
          </div>

          <div className={styles.rightContent}>
            <h3 className={styles.whyJoinHeading}>
              No Subscription Required For Free Trial!
            </h3>
            <ul
              className={styles.benefitsList}
              style={{
                fontSize: '16px',
              }}
            >
              🔔 Unlimited use of the QuickCam camera app Premium Features{' '}
              <br />
              🛡️ Get Founding Member, Beta Test or Early Adopter Badge <br />
              🚀 Access to Quickie and Speedy Community Meme Coin launches{' '}
              <br />
              🤝 Pre-build referral network and followers
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FreeBeta;
